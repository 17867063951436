module.exports = {
  title: `OZNote`,
  description: `Blog posted about software & development.`,
  author: `OTTZIL (유진)`,
  comments: {
    strongToken: `\\`,
    prefix: `안녕하세요.`,
    values: [
      `익숙한 것을 새롭게 만드는`,
      `가치를 만드는`,
      `사람에게 가치를 두는`,
    ],
    postfix: `\\창업가\\이자, \\개발자\\입니다.`,
    description: `Software Engineer interested in web, app, study, toy-projects and Start-UP.`,
  },
  siteUrl: `https://oznote.io`,
  icon: `content/assets/icon.jpg`,
  email: `eugenejeon.me@gmail.com`,
  social: {
    twitter: `@eugenejeon`,
    github: `eugenejeonme`,
    medium: ``, // `@eugenejeon.me`,
    linkedin: `eugenejeon`,
    facebook: `eugenejeon.me`,
    instagram: `eugenejeon.me`,
  },
  keywords: [`blog`, `OTTZIL`, `OZNote`],
  comment: {
    disqusShortName: ``, // Disqus-short-name. check disqus.com.
    utterances: ``, // Repository for archive comment
  },
  sponsor: {
    buyMeACoffeeId: ``,
    googleAdsense: {
      client: `ca-pub-7057617963913475`, // Add your google adsense publisherId `ca-pub-xxxxxxxxxx`
      slot: {
        postAd: `8589185279`, // Default Display Ads
      },
    },
  },
  share: {
    facebookAppId: ``, // Add facebookAppId for using facebook share feature v3.2
    revueId: ``,
  },
  analytics: {
    google: `G-XZJ07GE7N2`, // Add your google analytics tranking ID
    sentry: ``, // sentry.io
  },
  optimize: {
    google: `OPT-T6N3VPF`,
  },
  gtag: `GTM-MSRTCZ5`,
  clarity: `bfq7lrbvy7`,
}
