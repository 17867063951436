// custom typefaces
require('typeface-noto-sans-kr')
require('typeface-catamaran')

// polyfill
require('intersection-observer')

// const Sentry = require('@sentry/browser')
const metaConfig = require('./gatsby-meta-config')

exports.onInitialClientRender = () => {
  // Initialize facebook sdk
  if (metaConfig.share.facebookAppId) {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: metaConfig.share.facebookAppId,
        xfbml: true,
        version: 'v3.2',
      })
      window.FB.AppEvents.logPageView()
    }
    ;(function (d, s, id) {
      if (d.getElementById(id)) {
        return
      }

      const js = d.createElement(s)
      const [fjs] = d.getElementsByTagName(s)

      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  // if (metaConfig.sentryDsn) {
  //   Sentry.init({
  //     dsn: metaConfig.sentryDsn,
  //   })
  // }
}

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// https://github.com/MexsonFernandes/gatsby-pwa-starter/blob/master/gatsby-browser.js
// export const onServiceWorkerUpdateReady = async args => {
//   // eslint-disable-next-line no-undef
//   const permissionResponse = await Notification.requestPermission()
//   if (permissionResponse === "granted") {
//     await args.serviceWorker.showNotification("Website update", {
//       body:
//         "Our website just got a little bit better. We reloaded the site with the update to ensure a smooth experience for you.",
//     })
//   }
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest version?`
//   )
//   if (answer === true) {
//     window.location.reload()
//   }
// }

// export const registerServiceWorker = () => true
